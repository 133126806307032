import { createI18n } from 'vue-i18n';
import messagesEN from '@/../lang/en.json';
import messagesNL from '@/../lang/nl.json';
import messagesDE from '@/../lang/de.json';

export const messages = {
  en: messagesEN,
  nl: messagesNL,
  de: messagesDE,
};

export const defaultLanguage = 'nl';
const preferred = navigator.language.split('-')[0];
const locale = messages[preferred] !== undefined ? preferred : defaultLanguage;

const i18n = createI18n({
  locale,
  messages,
});

export default i18n;

export const languages = [
  { locale: 'en', name: 'English' },
  { locale: 'nl', name: 'Nederlands' },
  { locale: 'de', name: 'Deutsch' },
];
