/* eslint-disable max-len */
import Section from '@/components/Section.vue';

export default [
  {
    path: '/',
    redirect: { name: 'admin' },
  },
  {
    path: '/:token',
    redirect: (to) => ({
      name: 'start',
      params: { token: to.params.token, lang: 'nl' },
    }),
  },
  {
    path: '/:token/:lang',
    component: () => import('./views/scan/Layout.vue'),
    props: (route) => ({ token: route.params.token, categoryId: +route.params.category }),
    redirect: { name: 'start' },
    children: [
      {
        name: 'start',
        path: 'start',
        component: () => import('@/views/scan/Start.vue'),
      },
      {
        name: 'category',
        path: 'category/:category',
        component: () => import('@/views/scan/Category.vue'),
      },
      {
        name: 'end',
        path: 'end',
        component: () => import('@/views/scan/End.vue'),
      },
    ],
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('./views/admin/Login.vue'),
  },
  {
    name: 'password',
    path: '/password',
    component: () => import('./views/admin/Password.vue'),
  },
  {
    name: 'password-reset',
    path: '/password-reset/:token',
    component: () => import('./views/admin/PasswordReset.vue'),
    props: (route) => ({ token: route.params.token, email: route.query.email }),
  },
  {
    path: '/admin',
    component: () => import('./views/admin/Layout.vue'),
    redirect: '/admin/scans',
    name: 'admin',
    children: [
      {
        name: 'admin-scans',
        path: 'scans',
        component: Section,
        redirect: { name: 'admin-scans-overview' },
        children: [
          {
            name: 'admin-scans-overview',
            path: '',
            props: {
              type: 'regular',
              heading: 'Scans',
              addText: 'Nieuwe scan',
              routePrefix: 'scans',
            },
            component: () => import('@/views/admin/Scans.vue'),
          },
          {
            name: 'admin-scans-add',
            path: 'add',
            component: () => import('@/views/admin/ScansAdd.vue'),
            props: {
              type: 'regular',
              showRanges: true,
              routePrefix: 'scans',
            },
          },
          {
            path: ':scan',
            props: (route) => ({ id: +route.params.scan, routePrefix: 'scans' }),
            component: () => import('@/views/admin/Scan.vue'),
            redirect: { name: 'admin-scans-view' },
            children: [
              {
                path: 'participants',
                component: Section,
                children: [
                  {
                    name: 'admin-scans-view',
                    path: '',
                    component: () => import('@/views/admin/ScansView.vue'),
                    props: (route) => ({ id: +route.params.scan, routePrefix: 'scans' }),
                  },
                  {
                    name: 'admin-scans-add-participant',
                    path: 'add',
                    component: () => import('@/views/admin/ScansAddParticipant.vue'),
                    props: (route) => ({
                      scanId: +route.params.scan,
                      id: +route.params.participant,
                    }),
                  },
                  {
                    name: 'admin-scans-view-participant',
                    path: ':participant',
                    component: () => import('@/views/admin/ScansViewParticipant.vue'),
                    props: (route) => ({
                      scanId: +route.params.scan,
                      id: +route.params.participant,
                    }),
                  },
                ],
              },
              {
                name: 'admin-scans-settings',
                path: 'settings',
                component: () => import('@/views/admin/ScansSettings.vue'),
                props: {
                  showRanges: true,
                  routePrefix: 'scans',
                },
              },
              {
                name: 'admin-scans-questions',
                path: 'questions',
                component: () => import('@/views/admin/ScansQuestions.vue'),
                props: {
                  showMultiplier: true,
                  instructions: 'Elke vraag moet te beantwoorden zijn met een rating van 1 (fully disagree) tot 5 (fully agree).',
                },
              },
            ],
          },
        ],
      },
      {
        name: 'admin-followups',
        path: 'followups',
        component: Section,
        redirect: { name: 'admin-followups-overview' },
        children: [
          {
            name: 'admin-followups-overview',
            path: '',
            props: {
              type: 'follow_up',
              heading: 'Verdiepingen',
              addText: 'Nieuwe verdieping',
              routePrefix: 'followups',
            },
            component: () => import('@/views/admin/Scans.vue'),
          },
          {
            name: 'admin-followups-add',
            path: 'add',
            component: () => import('@/views/admin/ScansAdd.vue'),
            props: {
              type: 'follow_up',
              routePrefix: 'followups',
            },
          },
          {
            path: ':scan',
            props: (route) => ({ id: +route.params.scan, routePrefix: 'followups' }),
            component: () => import('@/views/admin/Scan.vue'),
            redirect: { name: 'admin-followups-view' },
            children: [
              {
                path: 'participants',
                component: Section,
                children: [
                  {
                    name: 'admin-followups-view',
                    path: '',
                    component: () => import('@/views/admin/ScansView.vue'),
                    props: (route) => ({ id: +route.params.scan, routePrefix: 'followups' }),
                  },
                  {
                    name: 'admin-followups-add-participant',
                    path: 'add',
                    component: () => import('@/views/admin/ScansAddParticipant.vue'),
                    props: (route) => ({
                      scanId: +route.params.scan,
                      id: +route.params.participant,
                    }),
                  },
                  {
                    name: 'admin-followups-view-participant',
                    path: ':participant',
                    component: () => import('@/views/admin/ScansViewParticipant.vue'),
                    props: (route) => ({
                      scanId: +route.params.scan,
                      id: +route.params.participant,
                    }),
                  },
                ],
              },
              {
                name: 'admin-followups-settings',
                path: 'settings',
                component: () => import('@/views/admin/ScansSettings.vue'),
                props: {
                  showRanges: false,
                  routePrefix: 'followups',
                },
              },
              {
                name: 'admin-followups-questions',
                path: 'questions',
                component: () => import('@/views/admin/ScansQuestions.vue'),
                props: {
                  showMultiplier: false,
                  instructions: 'Elke vraag moet te beantwoorden zijn met een open antwoord.',
                },
              },
            ],
          },
        ],
      },
      {
        name: 'admin-participants',
        path: 'participants',
        component: Section,
        redirect: { name: 'admin-participants-overview' },
        children: [
          {
            name: 'admin-participants-overview',
            path: '',
            component: () => import('@/views/admin/Participants.vue'),
          },
          {
            name: 'admin-participants-view',
            path: ':participant',
            component: () => import('@/views/admin/ParticipantsView.vue'),
            props: (route) => ({ id: +route.params.participant }),
          },
          {
            name: 'admin-participants-add',
            path: 'add',
            component: () => import('@/views/admin/ParticipantsAdd.vue'),
          },
        ],
      },
      {
        name: 'admin-coaches',
        path: 'coaches',
        component: Section,
        redirect: { name: 'admin-coaches-overview' },
        children: [
          {
            name: 'admin-coaches-overview',
            path: '',
            component: () => import('@/views/admin/Coaches.vue'),
          },
          {
            name: 'admin-coaches-view',
            path: ':id',
            component: () => import('@/views/admin/CoachesView.vue'),
            props: (route) => ({ id: +route.params.id }),
          },
          {
            name: 'admin-coaches-add',
            path: 'add',
            component: () => import('@/views/admin/CoachesAdd.vue'),
          },
        ],
      },
    ],
  },
];
