import { createApp } from 'vue';
import apiClient from '@/apiClient';
import router from '@/plugins/router';
import i18n from '@/plugins/i18n';

import App from '@/App.vue';

/* Global style */
import '../css/styles.css';
import 'redactor/src/redactor.css';

const app = createApp(App)
  .use(router)
  .use(i18n);

app.config.globalProperties.apiClient = apiClient;

router.isReady().then(() => {
  app.mount('#app');
});
